<template>
	<div>
		<v-tooltip right>
			<template v-slot:activator="{ on }">
				<v-btn small bottom right pa-4 v-on="on" @click="openDialog">
					<v-icon>icon-plus</v-icon>
				</v-btn>
			</template>
			<span>Написать рецензию</span>
		</v-tooltip>
		<component :is="form" ref="form" :channel="channel"/>
	</div>
</template>

<script>
export default {
	props: ["channel"],
	data() {
		return {};
	},
	methods: {
		openDialog() {
			this.$refs.form.openDialog();
		}
	},
	computed: {
		form() {
			return () => import("./helper/review-form.vue");
		}
	}
};
</script>
