<template>
	<div>
		<v-alert v-model="info" type="info" dismissible colored-border border="left">
			Оповещения о выходе новых серий вы можете получать через
			<router-link :to="{ name: 'profile' }">уведомления браузера</router-link> или через
			<router-link :to="{ name: 'pin' }">телеграм</router-link>.
		</v-alert>
	</div>
</template>

<script>
//import Notification from "../../components/helper/notification.vue";
export default {
	props: [],
	data() {
		return {
			info: true,
		};
	},
	created() {
		this.info = !localStorage.getItem("channel-notify-closed");
		this.getSubscription();
	},
	watch: {
		info(val) {
			if (!val) {
				localStorage.setItem("channel-notify-closed", true);
			}
		}
	},
	methods: {
		getSubscription() {
			if (!("Notification" in window && "serviceWorker" in navigator)) {
				return;
			}

			let t = this;
			navigator.serviceWorker.ready.then((swRegistration) => {
				swRegistration.pushManager
					.getSubscription()
					.then((sub) => {
						if (sub !== null) t.info = false;
					});
			});
		}
	}
};
</script>
