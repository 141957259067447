<template>
	<v-container fixed>
		<v-layout row v-if="!channel">
			<Progress :error="error" :loading="loading"></Progress>
		</v-layout>

		<template v-if="channel">
			<v-layout row wrap>
				<v-flex xs12>
					<material-card id="main-card" color="info" :title="channel.title">
						<template v-slot:headerRight>
							<v-btn text @click="toView">Смотреть</v-btn>
						</template>
						<v-card>
							<!--<v-btn v-if="channel.count_video && !toPay" outlined block height="42" @click="toView">
								<v-icon>icon-play</v-icon>&nbsp;Смотреть
							</v-btn>-->
							<component v-if="toPay" :is="accessDeny" />
							<v-card-text>
								<v-layout row wrap align-start>
									<div id="sidebar">
										<image-dialog
											:image_original="getPhotoPath('channel', channel.id) + channel.photo + '_original.jpg'"
											:title="channel.title"
											:image="getPhotoPath('channel', channel.id) + channel.photo + '.jpg'"
										></image-dialog>
									</div>
									<div id="main">
										<channel-notify />
										<v-layout justify-space-between row wrap xs12 px-3 pb-5>
											<div>
												<div v-if="channel.movie.year" class="year">
													<span class="head">Год</span>
													<span>
														<router-link
															:to="{ name: 'channelList', params: { section: channel.section }, query: { years: channel.movie.year }}"
														>{{ channel.movie.year }}</router-link>
													</span>
												</div>
												<div v-if="channel.movie.country.length">
													<span class="head">Страна</span>
													<span
												v-for="(name, id) in channel.movie.country"
												:key="id"
												class="text-center"
											>
														<router-link
															:to="{ name: 'channelList', params: { section: channel.section }, query: { tag: name }}"
														>{{ name }} </router-link>
													</span>
												</div>
											</div>

											<div>
												<v-progress-circular
													v-if="channel.rating && channel.rating.kinopoisk"
													:value="channel.rating.kinopoisk * 10"
													color="primary"
													size="48"
												>{{ channel.rating.kinopoisk }}</v-progress-circular>
											</div>

											<div>
												<v-btn v-if="vote != -1" icon small @click.prevent="onVote(1)">
													<v-icon :color="vote == 1 ? 'red' : ''">icon-thumbs-up</v-icon>
												</v-btn>
												<v-btn v-if="vote != 1" icon small @click.prevent="onVote(-1)">
													<v-icon :color="vote == -1 ? 'red' : ''">icon-thumbs-down</v-icon>
												</v-btn>
												<v-tooltip top>
													<template v-slot:activator="{ on }">
														<v-btn
															icon
															class="fave offset"
															:class="fave ? 'in' : ''"
															small
															v-on="on"
															@click.prevent="onFave('fave')"
														>
															<v-icon color="red">icon-heart-empty</v-icon>
														</v-btn>
													</template>
													<span>{{ fave ? 'Удалить из избранного' : 'Добавить в избранное' }}</span>
												</v-tooltip>
												<v-tooltip top>
													<template v-slot:activator="{ on }">
														<v-btn
															icon
															class="look_later fave"
															:class="follow ? 'in' : ''"
															small
															v-on="on"
															@click.prevent="onFave('subscribe')"
														>
															<v-icon color="green">icon-bookmark</v-icon>
														</v-btn>
													</template>
													<template v-if="!follow">
														<span v-if="channel.section == 'movie'">Посмотреть позже</span>
														<span v-if="channel.section == 'series'">Добавить в "мои сериалы"</span>
														<span v-if="channel.section == 'anime'">Добавить в "мои аниме"</span>
													</template>
													<template v-else>
														<span>Удалить из списка просмотра</span>
													</template>
												</v-tooltip>
												<Menu v-if="$store.state.user.user.type >= 0" :channel="channel" />
											</div>
										</v-layout>

										<div v-if="channel.movie.production_company.length">
											<span class="head">Производство</span>
											<span
												v-for="(name, id) in channel.movie.production_company"
												:key="id"
												class="text-center"
											>
												<v-chip outlined small>
													<router-link
														:to="{ name: 'channelList', params: { section: channel.section }, query: { tag: name }}"
													>{{ name }}</router-link>
												</v-chip>
											</span>
										</div>
										<div v-if="genres">
											<span class="head">Жанр</span>
											<span v-for="(name, id) in genres" :key="id" class="text-center">
												<v-chip outlined small>
													<router-link
														:to="{ name: 'channelList', params: { section: channel.section }, query: { category: id }}"
													>{{ name }}</router-link>
												</v-chip>
											</span>
										</div>
										<div v-if="channel.movie.person_cache.director">
											<span class="head">Режиссёр</span>
											<span
												v-for="(name, id) in channel.movie.person_cache.director"
												:key="id"
												class="text-center"
											>
												<v-chip outlined small>
													<router-link :to="{ name: 'person', params: { id: id }}">{{ name }}</router-link>
												</v-chip>
											</span>
										</div>
										<div v-if="channel.movie.person_cache.writer">
											<span class="head">Сценаристы</span>
											<span
												v-for="(name, id) in channel.movie.person_cache.writer"
												:key="id"
												class="text-center"
											>
												<v-chip small outlined>
													<router-link :to="{ name: 'person', params: { id: id }}">{{ name }}</router-link>
												</v-chip>
											</span>
										</div>
										<div v-if="channel.movie.person_cache.actor">
											<v-bottom-sheet v-model="actor_sheet" inset>
												<v-sheet>
													<v-progress-linear indeterminate rounded v-if="!staff.actor"></v-progress-linear>
													<v-layout>
														<v-flex xs6>
															<staff-list :staff="staff.actor" title="Актёры"></staff-list>
														</v-flex>
														<v-flex xs6>
															<staff-list :staff="staff.director" title="Режиссёры"></staff-list>
														</v-flex>
													</v-layout>
												</v-sheet>
											</v-bottom-sheet>
											<span class="head">Актёры</span>
											<span
												v-for="(name, id) in channel.movie.person_cache.actor"
												:key="id"
												class="text-center"
											>
												<v-chip small outlined>
													<router-link :to="{ name: 'person', params: { id: id }}">{{ name }}</router-link>
												</v-chip>
											</span>
											<v-btn @click="displayStaff()" x-small icon color="green">
												<v-icon>icon-ellipsis</v-icon>
											</v-btn>
										</div>
										<div v-if="channel.movie.date_release">
											<span class="head long">Дата премьеры в мире</span>
											<span>{{ channel.movie.date_release }}</span>
										</div>
										<div v-if="channel.movie.date_release_rus">
											<span class="head long">Дата премьеры в России</span>
											<span>{{ channel.movie.date_release_rus }}</span>
										</div>
										<div v-if="channel.movie.date_release_rus">
											<span class="head long">Дата премьеры в России</span>
											<span>{{ channel.movie.date_release_rus }}</span>
										</div>
										<div v-if="channel.movie.rated_age">
											<span class="head long">Возрастной рейтинг</span>
											<span>{{ channel.movie.rated_age }}</span>
										</div>
										<div v-if="channel.movie.quality">
											<span class="head long">Качество</span>
											<span>{{ channel.movie.quality }}</span>
										</div>
										<div v-if="voice">
											<span class="head long">Озвучка</span>
											<span>{{ voice }}</span>
										</div>

										<span class="head">Описание</span>
										<expand
											:description="channel.description"
											:description_extra="channel.description_extra"
										/>
									</div>
								</v-layout>
							</v-card-text>
						</v-card>
					</material-card>

					<template v-if="!toPay">
						<material-card
							class="card-tabs layer"
							color="info"
							v-if="reviews.length || sets.length || sim.length || tree.length"
						>
							<v-flex slot="header">
								<v-tabs v-model="contentTab" slider-color="white" show-arrows>
									<v-tab class="mr-3" v-if="reviews.length">Рецензии</v-tab>
									<v-tab class="mr-3" v-if="sets.length">Подборки</v-tab>
									<v-tab class="mr-3" v-if="sim.length">Вам может понравиться</v-tab>
									<v-tab class="mr-3" v-if="tree.length">Порядок просмотра</v-tab>
									<v-tab class="mr-3" v-if="!reviews.length">Написать рецензию</v-tab>
								</v-tabs>
							</v-flex>

							<v-tabs-items v-model="contentTab">
								<v-tab-item v-if="reviews.length">
									<review-list :reviews="reviews" />
									<review-button :channel="channel" />
								</v-tab-item>
								<v-tab-item v-if="sets.length" class="slide">
									<channel-slide :items="sets" type="set" />
								</v-tab-item>
								<v-tab-item v-if="sim.length" class="slide">
									<channel-slide :items="sim" type="channel">
										<template v-slot:more>
											<v-slide-item v-if="hasMoreSim">
												<a href='#' @click="moreSim">
													<v-img
														:src="'/image/more-150.jpg'"
														:srcset="'/image/more-300.jpg 2x'"
														:width="150"
														:height="222"
														eager
													>
														<v-container fill-height fluid text-center has-more>
															<div class="channel-title">Показать все</div>
														</v-container>
													</v-img>
												</a>
											</v-slide-item>
										</template>
									</channel-slide>
								</v-tab-item>
								<v-tab-item v-if="tree.length" class="tree">
									<channel-tree :tree="tree" />
								</v-tab-item>
								<v-tab-item v-if="!reviews.length">
									<review-button :channel="channel" />
								</v-tab-item>
							</v-tabs-items>
						</material-card>

						<material-card class="card-tabs view layer" color="info" v-if="seasons.length">
							<v-flex slot="header">
								<v-tabs v-model="seasonTab" slider-color="white" show-arrows>
									<v-tab class="mr-3" v-for="season in seasons" :key="season.id" v-html="season.title" />
								</v-tabs>
							</v-flex>

							<v-tabs-items v-model="seasonTab" ref="tabContent">
								<v-tab-item v-for="(season, idx) in seasons" :key="season.id">
									<Progress :error="video_error" :loading="video_loading"></Progress>
									<video-list
										v-show="video[idx]"
										:items="video[idx]"
										:channel_title="channel.title"
										:strip_season="true"
									></video-list>
								</v-tab-item>
							</v-tabs-items>
						</material-card>
						<v-card class="view" v-else>
							<player ref="player" v-if="movie" :video="movie" :section="channel.section" />
							<video-list v-if="video[0]" :items="video[0]" :channel_title="channel.title"></video-list>
						</v-card>
					</template>
				</v-flex>
			</v-layout>
		</template>
	</v-container>
</template>

<script>
import { Helper } from "../mixins/Helper";
import ImageDialog from "../components/helper/image-dialog.vue";
import Player from "../components/player.vue";
import VideoList from "../components/video-list.vue";
import StaffList from "../components/helper/staff-list";
import Expand from "../components/expand";
import ChannelSlide from "../components/channel-slide.vue";
import ReviewList from "../components/review-list.vue";
import ReviewButton from "../components/review-button.vue";
import Menu from "../components/helper/channel-menu.vue";
import ChannelTree from "../components/helper/channel-tree.vue";
import ChannelNotify from "../components/helper/channel-notify.vue";

function fetchData(address) {
	return window.fetchData("/api/channel/item/" + address, {});
}

export default {
	mixins: [Helper],
	components: {
		ImageDialog,
		Player,
		VideoList,
		StaffList,
		Expand,
		ChannelSlide,
		ReviewList,
		ReviewButton,
		Menu,
		ChannelTree,
		ChannelNotify
	},
	data() {
		return {
			loading: true,
			error: null,
			address: null,
			channel: null,
			seasons: null,
			video: [],
			seasonTab: 0,
			contentTab: 0,
			video_error: null,
			video_loading: true,
			actor_sheet: false,
			movie: null,
			sets: null,
			tree: null,
			reviews: null,
			sim: null,
			follow: null,
			fave: null,
			staff: {
				actor: null
			},
			genres: null,
			toPay: null,
			menu: false,
			vote: null,
			info: true,
			hasMoreSim: false
		};
	},
	created() {
		document.title = "";
		this.address = this.$route.params.address;
		if (process.env.NODE_ENV != "production") {
			this.$nextTick(() => {
				if (this.loading == true) this.fetchData();
			});
		}
	},
	watch: {
		seasonTab: {
			handler: function(val) {
				if (
					typeof val == "undefined" ||
					typeof this.seasons[val] == "undefined"
				)
					return;
				let season_id = this.seasons[val].id;
				if (typeof this.video[val] == "undefined")
					this.fetchSeason(season_id, val);
			}
		}
	},
	computed: {
		accessDeny() {
			return () => import("../components/helper/access-deny.vue");
		},
		sectionName() {
			let section = this.channel.section;
			if (section == "movie") return "Фильм";
			else if (section == "series") return "Сериал";
			return "Аниме";
		},
		voice() {
			if (!this.movie) return '';
			return this.getVoice(this.movie);
		}
	},
	methods: {
		setData(data) {
			let t = this;
			t.loading = false;
			t.video_loading = false;

			if (!data.error) {
				t.channel = data.channel;
				t.seasons = data.seasons;
				t.movie = data.movie;
				t.genres = data.genres;
				t.seasonTab = data.view_season;
				t.sets = data.sets;
				t.reviews = data.reviews;
				t.sim = data.sim;
				t.fave = data.fave;
				t.follow = data.follow;
				t.tree = data.tree;
				t.video[t.seasonTab] = data.video;
				t.vote = data.vote;
				t.hasMoreSim = data.sim.length == 10;
				document.title = t.channel.title;

				if (data.toPay) {
					t.toPay = true;
				}
			} else {
				t.error = data;
			}
		},
		async fetchData() {
			let t = this;
			t.error = t.channel = t.series = null;
			t.loading = true;

			let data = await fetchData(t.address);
			t.setData(data);
		},
		fetchSeason(id, idx) {
			let t = this;
			t.$refs.tabContent.$el.style.minHeight =
				t.$refs.tabContent.$el.scrollHeight + "px";
			t.video_error = null;
			t.video_loading = true;
			window.ajax("/api/channel/season/" + id, [], function(data, error) {
				if (!error) {
					t.video[idx] = data.video;
				} else {
					t.video_error = data;
				}
				t.video_loading = false;
			});
		},
		displayStaff() {
			let t = this;
			t.actor_sheet = true;
			if (t.staff.actor) return;

			window.ajax("/api/channel/staff/" + t.channel.id, [], function(
				data,
				error
			) {
				//console.log(data);
				if (!error) {
					t.staff.actor = data.actor;
					t.staff.director = data.director;
				}
			});
		},
		onFave: function(type) {
			let t = this;
			window.ajax(
				"/api/" + type + "/set/" + this.channel.id,
				[],
				function(data, error) {
					if (!error) {
						if (type == "fave") t.fave = data.status;
						else {
							if (data.status) t.follow = { notify_sms: 0 };
							else t.follow = null;
						}
					}
				}
			);
		},
		onVote: function(delta) {
			let t = this;
			if (t.vote == delta) return;
			window.ajax(
				"/api/channel/vote/" + t.channel.id,
				{ body: { delta: delta } },
				function(data) {
					if (!data.error) {
						t.vote = delta;
					}
				}
			);
		},
		toView: function() {
			this.$vuetify.goTo(".view");
			if (this.movie && this.$refs.player) this.$refs.player.play();
		},
		moreSim: function() {
			let t = this;
			window.ajax(
				"/api/channel/similar/" + this.channel.id,
				[],
				function(data, error) {
					if (!error) {
						t.sim = data.items;
						t.hasMoreSim = false;
					}
				}
			);
			return false;
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.address = to.params.address;
		this.contentTab = 0;
		this.fetchData();
		next();
	},
	async beforeRouteEnter(to, from, next) {
		let data = await fetchData(to.params.address);

		next(vm => {
			vm.setData(data);
		});
	}
};
</script>
<style lang="scss" scoped>
.container {
	max-width: 1100px;
}
#sidebar {
	flex: 290px;
	width: 290px;
	flex-grow: 0;
	padding: 0 5px;
	/*margin: auto;*/
}
@media only screen and (max-width: 600px) {
	#sidebar {
		padding: 0;
		margin: auto;
	}
}

#main {
	flex: 1;
	min-width: 250px;
}
#main > div,
.year {
	padding-bottom: 5px;
}
.head {
	color: black;
	width: 100px;
	display: inline-block;
}
.theme--dark .head {
	color: white;
}

.head.long {
	width: 200px;
}

.tree {
	max-width: 600px;
}

.fave:hover,
.fave.in {
	background-color: #495057 !important;
	opacity: 0.7;
}

.has-more {
	color: white;
}

#main-card ::v-deep {
	.title-right {
		width: 150px;
		height: 66px;
		background-color: green;
		border-bottom-right-radius: 4px;
		border-top-right-radius: 4px;
		button {
			width: 100%;
			height: 100%;
			background-color: green;
		}
	}
	@media only screen and (max-width: 500px) {
		.title-right {
			width: 100%;
			border-top-right-radius: 0px;
			border-bottom-left-radius: 4px;
		}
	}
	.v-card--material__header.v-card {
		padding: 0;
	}
	.layout .title-left {
		margin: 15px;
	}
}
</style>