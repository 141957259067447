<template>
	<v-expansion-panels v-model="reviewActive">
		<v-expansion-panel v-for="(review,i) in reviews" :key="i">
			<v-expansion-panel-header>
				<v-layout>
					<v-flex xs6 v-html="review.title" />
					<v-flex xs6 v-if="review.rate">
						<v-progress-linear height="20" value="review.rate * 20">{{ review.rate }} / 5</v-progress-linear>
					</v-flex>
				</v-layout>
			</v-expansion-panel-header>
			<v-expansion-panel-content>
				<Progress :loading="reviewLoading"/>
				<span v-html="text[i]"/>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>
<script>

export default {
	props: ["reviews"],
	data() {
		return {
			reviewActive: undefined,
			reviewLoading: false,
			text: []
		};
	},
	watch: {
		reviewActive: function(val) {
			let review = this.reviews[val];
			if (review && !this.text[val]) this.fetchData(val, review);
		}
	},
	methods: {
		fetchData(idx, review) {
			let t = this;
			t.reviewLoading = true;
			window.ajax(
				"/api/channel/review/" + review.id,
				[],
				function(data, error) {
					t.reviewLoading = false;
					if (!error) {
						t.text[idx] = data;
					} else {
						t.error = data;
					}
				}
			);
		}
	}
};
</script>
<style scoped>
</style>
