<template>
	<v-speed-dial
		v-model="menu"
		direction="left"
		style="display: inline;"
	>
		<template v-slot:activator>
			<v-btn v-model="menu" color="blue darken-2" icon small>
				<v-icon v-if="menu">icon-close</v-icon>
				<v-icon v-else>icon-tools</v-icon>
			</v-btn>
		</template>
		<v-btn
			fab
			dark
			x-small
			color="green"
			:href="'https://oveg.ru/' + channel.section + '/' + channel.address + ''"
			target="_blank"
		>
			<v-icon>icon-logout</v-icon>
		</v-btn>
		<v-btn
			fab
			dark
			x-small
			color="red"
			:href="'https://oveg.ru/' + channel.section + '/' + channel.address + '/?section=delete'"
			target="_blank"
		>
			<v-icon>icon-trash</v-icon>
		</v-btn>
		<v-btn
			fab
			dark
			x-small
			color="blue"
			:href="'https://oveg.ru/upload/channel/' + channel.address + ''"
			target="_blank"
		>
			<v-icon>icon-upload</v-icon>
		</v-btn>
		<v-btn
			fab
			dark
			x-small
			color="green"
			:href="'https://oveg.ru/' + channel.section + '/' + channel.address + '/?section=edit'"
			target="_blank"
		>
			<v-icon>icon-pencil</v-icon>
		</v-btn>
	</v-speed-dial>
</template>

<script>
export default {
	props: ["channel"],
	data() {
		return {
			menu: false
		};
	},
	methods: {}
};
</script>
