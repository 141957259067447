<template>
	<v-list>
		<v-subheader>{{ title }}</v-subheader>
		<v-list-item
			v-for="person in staff"
			:key="person.id"
			:to="{ name: 'person', params: { id: person.id }}"
		>
			<v-list-item-avatar v-if="person.photo">
				<v-img eager :src="getPhotoPath('person', person.id) + person.photo + '_48.jpg?v=' + person.photo_ver" />
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title>{{ person.name_rus ? person.name_rus : person.name_orig }}</v-list-item-title>
				<v-list-item-subtitle v-if="person.name_rus">{{ person.name_orig }}</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>

<script>
import { Helper } from "../../mixins/Helper";

export default {
	mixins: [Helper],
	props: ["staff", "title"],
	data() {
		return {};
	}
};
</script>
