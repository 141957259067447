<template>
	<div>
		<v-layout row wrap v-for="(channels,level) in tree" :key="level">
			<v-flex
				v-for="tchannel in channels"
				:key="tchannel.id"
				:class="'xs' + (12 / channels.length)"
				class="text-center"
			>
				<v-card outlined>
					<v-card-text>
						<router-link
							:to="{ name: 'channelPage', params: { address: tchannel.address }}"
							v-html="tchannel.title"
						/>
					</v-card-text>
				</v-card>
			</v-flex>

			<v-flex xs12 class="text-center" v-if="level < tree.length - 1">
				<v-icon>icon-down</v-icon>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
export default {
	props: ["tree"],
	data() {
		return {};
	}
};
</script>
